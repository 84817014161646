import Cookie from "js-cookie";
import * as React from "react";

import { AuthFormWrapper } from "components/authentication/AuthFormWrapper";
import { LoginForm } from "components/authentication/login";
import { LoginHeader } from "components/authentication/login/LoginHeader";

import { useAuth } from "hooks/useAuth";
import { useSlugRouter } from "hooks/useSlugRouter";

const Login: React.FC = () => {
  const { client } = useAuth();
  const router = useSlugRouter();
  const cSlug = Cookie.get("slug");

  React.useEffect(() => {
    if (client && cSlug) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(`/${cSlug}/`);
    }
  }, [client, router, cSlug]);

  return (
    <AuthFormWrapper title="Login">
      <LoginHeader />
      <LoginForm isGeneral />
    </AuthFormWrapper>
  );
};

export default Login;
